
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col" v-can="'DASHBOARD_CRM_TOTAL_ENABLE'">
        <!-- Total Marketing Grap-->

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 justify-content-end">
                <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
                <date-picker
                  v-model="year"
                  :format="momentFormat"
                  type="year"
                  :placeholder="'Select Year'"
                  @input="fecthData"
                >
                </date-picker>
              </div>
              <div class="col-md-6">
                <select
                  v-model="createdBy"
                  v-can="'CRM_ADMIN_ENABLE'"
                  class="form-control"
                  name="created_by"
                  data-live-search="true"
                  placeholder="Choose Marketing"
                  @change="fecthData"
                >
                  <option value="null" selected>All Marketing</option>
                  <option
                    v-for="(value, key) in listUser"
                    :key="key"
                    :value="value.id"
                  >
                    {{ value.profile.name_user }}
                  </option>
                </select>
              </div>
              <div class="col-md-12">
                <apexchart
                  class="apex-charts"
                  height="380"
                  type="area"
                  :series="linewithDataChart.series"
                  :options="linewithDataChart.chartOptions"
                ></apexchart>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div
                    v-if="createdBy !== 'null' && createdBy !== null"
                    v-can="'CRM_ADMIN_ENABLE'"
                    class="col-md-12"
                  >
                    created by {{ dataUser.username_user }}
                  </div>
                  <div class="col-md-4">
                    Total Candidate {{ totalCandidate }}
                  </div>
                  <div class="col-md-4"> Total Visit {{ totalVisit }} </div>
                  <div class="col-md-4"> Total Closing {{ totalClosing }} </div>
                  <div class="col-md-4">
                    Total Prospect {{ totalPending }}
                  </div>
                  <div class="col-md-4"> Total Reject {{ totalReject }} </div>
                </div>
              </div>
            </div>
            <!-- <LaporanPeristiwaBarChart :height="350" /> -->
          </div>
        </div>
      </div>
      <div class="col" v-can="'DASHBOARD_CRM_JOBFILE_STATUS_ENABLE'">
        <!-- Jobfile by Status -->
        <div class="card-box">
          <h4 class="header-title pb-3">Jobfile by Status</h4>
          <apexchart
            class="apex-charts"
            height="320"
            type="pie"
            :series="PieJobfile.series"
            :options="PieJobfile.chartOptions"
          ></apexchart>
        </div>
      </div>
      <div class="col-md-12">
        <!-- calender -->
        <div class="card-box" v-can="'DASHBOARD_CRM_CALENDAR_ENABLE'">
          <!-- <schedule/> -->
          <!-- <FullCalendar ref="fullCalendar" default-view="dayGridMonth" :header="{ left: 'prev,next today',center: 'title',right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek' }" :plugins="calendarPlugins" :events="listScheduleCustomer" :weekends="calendarWeekends" :theme-system="themeSystem" /> -->
          <FullCalendar
            ref="fullCalendar"
            default-view="dayGridMonth"
            :header="{ left: 'prev,next', center: 'title', right: 'today' }"
            :plugins="calendarPlugins"
            :events="listScheduleCustomer"
            :weekends="calendarWeekends"
            :theme-system="themeSystem"
            :eventTimeFormat="{
   hour: '2-digit',
   minute: '2-digit',
   hour12: false,
}"
            @eventClick="editEvent"
          />
        </div>
      </div>

      <!-- modal view -->
      <b-modal
        ref="my-modal"
        v-model="eventModal"
        :title="'OakUpdates ' + releaseVersion + ' ( ' + releaseScedule + ' )'"
        header-bg-variant="white"
        hide-footer
        size="lg"
        title-class="text-black font-18"
        :no-close-on-backdrop="true"
        :close-on-esc="false"
        :hide-header-close="true"
      >
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="form-group" v-html="releseDescription"> </div>
              </div>
            </div>
          </div>
          <b-button
            class="mt-3"
            variant="outline-danger"
            block
            @click="hideModal"
            >Close Me</b-button
          >
        </div>
      </b-modal>

	  <b-modal v-model="eventModal2" title="Detail Event" header-bg-variant="white" title-class="text-black font-18"
							 hide-footer body-class="p-0">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-md-12 border-bottom">
								<label for="">Marketing</label>
								<p class="font-weight-bold">
									{{marketing}}
								</p>
							</div>
							<div class="col-md-12 border-bottom">
								<div class="row">
									<div class="col-md-4 border-right pt-2">
										<label for="">Customer</label>
										<p class="font-weight-bold">
											{{customer}}
										</p>

									</div>
									<div class="col-md-4 border-right pt-2">
										<label for="">Schedule Time</label>
										<p class="font-weight-bold">
											{{scheduleTime}}
										</p>

									</div>
									<div class="col-md-4 pt-2">
										<label for="">Remark</label>
										<p class="font-weight-bold">
											{{remarkSchedule}}
										</p>

									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
			</b-modal>

      <!-- MODAL -->
      <!-- <b-modal v-model="modalShow">Hello From Modal!</b-modal> -->
    </div>
  </Layout>
</template>

<script>
// import countTo from 'vue-count-to'

import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import DatePicker from 'vue2-datepicker'
import PageHeader from '@components/page-header'
import moment from 'moment'
// import schedule from '@components/ContactManagement/VisitSchedule.vue'
// import LaporanPeristiwaBarChart from './charts/LaporanPeristiwaBarChart.vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import listPlugin from '@fullcalendar/list'

import { mapState, mapActions } from 'vuex'
// import { calendarEvents } from '@/src/components/ContactManagement/dataSchedule';

/**
 * Dashboard-3 component
 */
export default {
  page: {
    title: 'Dashboard',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    // countTo,
    FullCalendar,
    DatePicker,
    Layout,
    PageHeader,
    // schedule
    // LaporanPeristiwaBarChart
  },
  props: {},
  data() {
    return {
      title: 'Dashboard',
      eventModal: false,
      eventModal2: false,
      releaseVersion: 'kosong',
      releaseScedule: 'tanggal',
      releseDescription: '',
      calendarWeekends: true,
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        bootstrapPlugin,
        listPlugin,
      ],
      themeSystem: 'standard',
      calendarEvents: [
        {
          id: '',
          title: '',
          start: '',
        },
      ],
      year: null,
      createdBy: null,
      dataPayload: {
        year: null,
        createdBy: null,
      },
      totalCandidate: 0,
      totalVisit: 0,
      totalClosing: 0,
      totalPending: 0,
      totalReject: 0,

      PieJobfile: {
        series: [10, 55, 1, 3, 6, 9, 10, 0],
        chartOptions: {
          labels: [
            'New Jobfile',
            'Info Inputed',
            'Buying Finished',
            'Invoice Uploaded',
            'Payment Uploaded',
            'Selling Finished',
            'Document Collected',
            'Finished',
          ],
          colors: [
            '#51C1AD',
            '#21A588',
            '#2481BE',
            '#EA4C40',
            '#9359A3',
            '#F6A11C',
            '#eee',
            '#A6A5A5',
          ],
          pieSliceText: 'value',
          legend: {
            show: true,
            position: 'right',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: -10,
          },
          dataLabels: {
            enabled: true,
            // pieSliceText: 'value',
            enabledOnSeries: undefined,
            formatter: function (val, opts) {
              return opts.w.config.series[opts.seriesIndex]
            },
            // formatter: function (val, opts) {
            //   return val * 100
            // },
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  height: 240,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
      },
      linewithDataChart: {
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          colors: ['#4fc6e1', '#1abc9c', '#896F89', '#F6A11C', '#eee'],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            width: [2, 2, 2, 2, 2],
            curve: 'smooth',
          },
          title: {
            text: ' s',
            align: 'left',
            style: {
              fontSize: '14px',
              color: '#ffffff',
            },
          },

          grid: {
            row: {
              colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.2,
            },
            borderColor: '#f1f3fa',
          },
          markers: {
            style: 'inverted',
            size: 6,
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            title: {
              text: 'Month',
            },
          },
          yaxis: {
            // tickAmount: 6
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
        series: [],
      },
      sum: 0,
      items: [
        {
          text: 'home',
          href: '/',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      // eventModal: false,
      marketing: '',
      customer: '',
      scheduleTime: '',
      remarkSchedule: '',
      id_user: '',
      momentFormat: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.year).format('YYYY') : ''
        },
      },
    }
  },
  computed: {
    ...mapState('customer', [
      'dataChart1',
      'dataChart2',
      'dataChart3',
      'dataChart4',
      'dataChart5',
      'listCustomer',
    ]),
    ...mapState('customerHistory', ['listScheduleCustomer']),
    ...mapState('user', ['listUser', 'dataUser']),
    ...mapState('jobfile', ['chart']),
    // totalItem: function () {
    // let sum = 0;
    // this.linewithDataChart.series.forEach(function (item) {
    //   this.sum += parseFloat(item.data);
    // });

    //   return this.sum;
    // }
  },
  mounted() {
    // if(!this.fecthData){
    // this.linewithDataChart.series.forEach(function (item) {
    //   this.sum += parseFloat(item.data);
    // });
    // console.log(this.$store.state.rootApi)
    this.fecthData()
    this.getUser()
    this.fecthDataPie()
    this.getEvents()
    this.getStatusRelease()
    this.getReleaseList()
    // }
  },
  methods: {
    ...mapActions('customer', [
      'getChart1',
      'getChart2',
      'getChart3',
      'getChart4',
      'getChart5',
      'getListCustomer',
    ]),
    ...mapActions('customerHistory', ['getScheduleCustomer']),
    ...mapActions('user', [
      'getListUser',
      'getUsername',
      'getUserRelease',
      'getUserLogin',
      'getUserReleaseUpdate',
    ]),
    ...mapActions('jobfile', ['getChart']),
    editEvent(info) {
      // console.log(info.event, 'isi modal event')
      // console.log(info.event.extendedProps.description, 'description')
      const date = info.event.start

      this.marketing = info.event.title
      this.scheduleTime = moment(date).format('LLLL')
      this.customer = info.event.extendedProps.customer
      this.remarkSchedule = info.event.extendedProps.description
      // this.edit = info.event
      // this.editevent.editTitle = this.edit.title
      this.eventModal2 = true
    },

    getEvents() {
      this.getScheduleCustomer()
        .then((res) => {
          // console.log('res', this.listScheduleCustomer)
          // console.log(this.calendarEvents, 'event')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getReleaseList() {
      try {
        this.getUserRelease()
          .then((response) => {
            console.log(response, 'list_release')
            this.releaseVersion = response.result[0].version
            this.releaseScedule = response.result[0].date_schedule
            this.releseDescription = response.result[0].description
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },

    getStatusRelease() {
      try {
        this.getUserLogin()
          .then((response) => {
            console.log(response, 'user_release')
            if (response.result.is_release_note === 1) {
              this.eventModal = true
            } else {
              this.eventModal = false
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    hideModal() {
      try {
        this.$refs['my-modal'].hide()
        this.getUserReleaseUpdate()
          .then((response) => {
            console.log(response, 'user_update')
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },

    getUser() {
      try {
        this.getListUser()
          .then((res) => {
            console.log(res, 'res user')
            // this.user_id = res.result[1].id
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    fecthDataPie() {
      this.getChart()
        .then((res) => {
          // console.log(this.chart)

          const pieJf = this.chart.map(function (value) {
            return value
          })

          this.PieJobfile.series = pieJf
          // console.log(this.PieJobfile.series, 'isi pie')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    fecthData() {
      try {
        // console.log('marketing', this.createdBy)
        // console.log('thisyear', this.year)
        // if(this.year){
        let year = this.year ? this.year.getUTCFullYear() : null
        // console.log('year', year)
        this.dataPayload.year = year

        this.dataPayload.createdBy = this.createdBy
        let idUser = this.dataPayload.createdBy
        // console.log('isUser', idUser)

        if (idUser != null) {
          this.getUsername(idUser)
            .then((res) => {
              // console.log(this.dataUser)
            })
            .catch((err) => {
              console.log(err)
            })
        }

        if (this.dataPayload.year) {
          this.linewithDataChart.series = []
          this.totalCandidate = 0
          this.totalVisit = 0
          this.totalClosing = 0
          this.totalPending = 0
          this.totalReject = 0
        }
        if (this.dataPayload.createdBy) {
          this.linewithDataChart.series = []
          this.totalCandidate = 0
          this.totalVisit = 0
          this.totalClosing = 0
          this.totalPending = 0
          this.totalReject = 0
        }
        this.getChart1(this.dataPayload)
          .then((res) => {
            // console.log(this.dataChart1)
            let chart1 = this.dataChart1
            // console.log('chart1', chart1)

            // eslint-disable-next-line no-unused-vars
            // let totalCandidate = 0
            for (var i = 0; i < chart1.length; i++) {
              this.totalCandidate += chart1[i]
            }

            // console.log('totalCandidate', this.totalCandidate)

            const newData = this.dataChart1.map(function (value) {
              return value
            })

            this.linewithDataChart.series.push({
              name: 'Candidate',
              data: newData,
            })
          })
          .catch((err) => {
            console.log(err)
          })

        this.getChart2(this.dataPayload)
          .then((res) => {
            // console.log(this.dataChart2)

            let chart2 = this.dataChart2
            // console.log('chart2', chart2)

            // eslint-disable-next-line no-unused-vars
            // let totalCandidate = 0
            for (var i = 0; i < chart2.length; i++) {
              this.totalVisit += chart2[i]
            }

            // console.log('totalVisit', this.totalVisit)

            const newData2 = this.dataChart2.map(function (value) {
              return value
            })
            this.linewithDataChart.series.push({
              name: 'Visit',
              data: newData2,
            })
          })
          .catch((err) => {
            console.log(err)
          })
        this.getChart3(this.dataPayload)
          .then((res) => {
            // console.log(this.dataChart3)
            let chart3 = this.dataChart3
            // console.log('chart3', chart3)

            // eslint-disable-next-line no-unused-vars
            // let totalCandidate = 0
            for (var i = 0; i < chart3.length; i++) {
              this.totalClosing += chart3[i]
            }

            // console.log('totalClosing', this.totalClosing)
            const newData3 = this.dataChart3.map(function (value) {
              return value
            })
            this.linewithDataChart.series.push({
              name: 'Closing',
              data: newData3,
            })
          })
          .catch((err) => {
            console.log(err)
          })

        // status chart4
        this.getChart4(this.dataPayload)
          .then((res) => {
            // console.log(this.dataChart4)
            let chart4 = this.dataChart4
            // console.log('chart4', chart4)

            // eslint-disable-next-line no-unused-vars
            // let totalCandidate = 0
            for (var i = 0; i < chart4.length; i++) {
              this.totalPending += chart4[i]
            }

            // console.log('totalPending', this.totalPending)
            const newData4 = this.dataChart4.map(function (value) {
              return value
            })
            this.linewithDataChart.series.push({
              name: 'Prospect',
              data: newData4,
            })
          })
          .catch((err) => {
            console.log(err)
          })

        // status chart5
        this.getChart5(this.dataPayload)
          .then((res) => {
            // console.log(this.dataChart5)
            let chart5 = this.dataChart5
            // console.log('chart5', chart5)

            // eslint-disable-next-line no-unused-vars
            // let totalCandidate = 0
            for (var i = 0; i < chart5.length; i++) {
              this.totalReject += chart5[i]
            }

            // console.log('totalReject', this.totalReject)
            const newData5 = this.dataChart5.map(function (value) {
              return value
            })
            this.linewithDataChart.series.push({
              name: 'Reject',
              data: newData5,
            })
          })
          .catch((err) => {
            console.log(err)
          })
        // console.log('datachart', this.linewithDataChart.series)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
<style>
.fc-time {
  color: white;
}
.fc-title {
  color: white;
}
</style>
